import React from 'react';
import ReactLoading from 'react-loading';

const Loader = () => {
  return (
    <div>
      <ReactLoading className="loader" type="bars" color="#2BCCB1" height={64} width={64} />
    </div>
  )
}

export default Loader;