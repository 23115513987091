import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import api from './helpers/apiClient';
import Loader from './components/Loader'
import moment from 'moment';
import parse from 'html-react-parser';
import $ from 'jquery';
import 'jquery-ui/ui/widgets/slider';
import 'jquery-ui/themes/base/slider.css';
import WaveSurfer from 'wavesurfer.js';
import RedeemGiftcardModal from './components/RedeemGiftcardModal'

class Giftcard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      youtube: false,
      showConfirmRedeemModal: false,
      redeeming: false,
      redeemError: ""
    };
  }

  componentDidMount() {
    const { match } = this.props;
    this.loadGiftcard(match.params.shortcode)
  }

  fullscreen = e => {
    e.preventDefault();
    const vid = document.getElementById('video');
    let userAgent = navigator.userAgent;
    if (userAgent.match(/firefox|fxios/i)) {
      if (!vid.mozFullScreen){
        vid.mozRequestFullScreen();
      }
      else{
        vid.mozCancelFullScreen();
      }
    }
    else { //Chrome, Safari, Edge
      if (!vid.webkitDisplayingFullscreen){
        vid.webkitEnterFullscreen();
      }
      else {
        vid.webkitExitFullscreen();
      }
    }
  }

  audioButton = e => {
    e.preventDefault();
    if ($('#a-slider').css('visibility') !== 'visible') {
      $('#a-slider').css('visibility', 'visible');
    }
    else {
      $('#a-slider').css('visibility', 'hidden');
    }
  }

  playPause = e => {
    e.preventDefault();

    const vid = document.getElementById('video');

    if (!vid.paused) {
      vid.pause();
    }
    else {
      vid.play();
    }
  }

  volumeButton = e => {
    e.preventDefault();
    if ($('#vol-slider').css('visibility') !== 'visible') {
      $('#vol-slider').css('visibility', 'visible');
    }
    else {
      $('#vol-slider').css('visibility', 'hidden');
    }
  }

  loadGiftcard = (shortcode) => {

    this.setState({
      loading: true
    });

    //check the length of the shortcode, if shortcode is 15 characters long, it is a branded giftcard, 
    //then redirect to the branded giftcard page
    if (shortcode.length === 15){
      return api(`/url_shortener/${shortcode}`, {
        method: 'GET'
      }).then((res) => {
        if (res.url){
          window.location.href = res.url
        }
      })
    }
    
    return api(`/giftcard/${shortcode}`, {
      method: 'GET'
    }).then((res) => {

      this.setState({
        loading: false,
        item: res
      });
    }).then(() => {
      const { item: { order_id, line_item_id, virtual_message } } = this.state;
      const vid = document.getElementById('video');
      const vdownload = document.getElementById('dl-link');
      const adownload = document.getElementById('a-dl-link');

      async function downloadMedia(link){
        const response = await fetch(link)
        const r = await response.blob()
        return r
      }

      const link = 'https://app.giftr.my/api/giftr/my/' + order_id + '/message/' + line_item_id

      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      /* AUDIO */
      var wavesurfer = WaveSurfer.create({
        container: '#waveform',
        waveColor: '#f5f5f5',
        progressColor: '#81d8d0',
        barWidth: 3,
        height: 90,
        responsive: true,
        hideScrollbar: true,
        ignoreSilenceMode: true,
        cursorWidth: 0,
        barRadius: 3,
      });

      if(virtual_message){
        fetch(link, requestOptions)
          .then(response => response.json())
          .then((result) => {
            $('.message').text(result.message.value);
            if (result.media){
              if (result.media.content_type.includes('video')){
                let source = document.createElement('source');
                source.src = result.media.src + '#t=0.001'
                source.type = result.media.content_type;
                $('#video').append(source);

                downloadMedia(result.media.src, "video/mp4").then((r)=>{
                  vdownload.setAttribute('href', window.URL.createObjectURL(r));
                });

                $('#vm-video').removeClass('hide');
              }
              else if (result.media.content_type.includes('audio')){
                wavesurfer.load(result.media.src)
                $('.c-audio').removeClass('hide');

                // if(navigator.userAgent.match('CriOS')){ //for ios Chrome
                //   downloadMedia(result.media.src).then((r)=>{
                //     adownload.addEventListener('touchstart', downloadChrome(event,r));
                //   });
                // }
                // else {
                downloadMedia(result.media.src).then((r)=>{
                  adownload.setAttribute('href', window.URL.createObjectURL(r));
                });
                // }
              }
              else if (result.media.src.includes("youtube")){
                let iframe = document.createElement('iframe');
                iframe.src = result.media.src
                $('#yt-embed').append(iframe)
                $('#yt-embed').removeClass('hide');
                this.setState({ youtube: true })
              }
            }
          })
          .catch(error => console.log('error', error));
      }


      //CURRENT TIME
      const currentTime = (value) => {
        let minute = Math.floor(value / 60);
        let second = Math.floor(value - minute * 60);

        if (second < 10) {
          second = "0" + second;
        }

        return minute + ":" + second;
      }

      //Play and pause
      wavesurfer.on('play', function() {
        $('#playPauseButton').toggleClass('pause');
      });
      wavesurfer.on('pause', function() {
        $('#playPauseButton').toggleClass('pause');
      });
      $('#playPauseButton').on({
        click: function(e){
          e.preventDefault();
          wavesurfer.playPause();
        },
        touchstart: function(e){
          e.preventDefault();
          wavesurfer.playPause();
        }
      })

      //Audio Time Update
      wavesurfer.on('ready', function() {
        $('#a-duration').html(currentTime(wavesurfer.getDuration()))
      })
      wavesurfer.on('audioprocess', function() {
        $('#a-current').html(currentTime(wavesurfer.getCurrentTime()))
      })

      //Volume Slider
      $("#a-slider").slider({
        orientation: "vertical",
        min: 0,
        max: 100,
        value: 100,
        range: "min",
        slide: function(event, ui) {
          wavesurfer.setVolume(ui.value/100);
        }
      });

      /* VIDEO */
      //Seek
      $("#seek-slider").slider({
        min: 0,
        max: 100,
        value: 0,
        range: "min",
        slide: function(event, ui) {
          vid.currentTime = vid.duration * (ui.value / 100);
        }
      })

      function seektimeupdate() {
        $("#seek-slider").slider('value', (vid.currentTime * (100 / vid.duration)))
      }

      //Volume Button
      $('#vm-video').mouseleave(function() {
        if ($('#vol-slider').css('visibility') == 'visible'){
          $('#vol-slider').css('visibility', 'hidden');
        }
      })
      $("#vol-slider").slider({
        orientation: "vertical",
        min: 0,
        max: 100,
        value: 100,
        range: "min",
        slide: function(event, ui) {
          vid.volume = (ui.value / 100);
        }
      });

      //Video Events
      $('#video').on({
        touchstart: function(e){
          e.preventDefault();
          if ($('.v-controls').css('transform') !== 'matrix(1, 0, 0, 1, 0, 0)'){
            $('.v-controls').css('transform', 'matrix(1, 0, 0, 1, 0, 0)');
          }
          else {
            $('.v-controls').css('transform', 'matrix(1, 0, 0, 1, 0, 52.2)');
          }
        },
        canplay: function(){
          $('#v-duration').html(currentTime(vid.duration));
        },
        play: function(){
          $('#play-pause').toggleClass('pause');
        },
        pause: function(){
          $('#play-pause').toggleClass('pause');
        },
        timeupdate: function(){
          seektimeupdate();
          $('#v-current').html(currentTime(vid.currentTime));
        }
      })

    })
  }

  handleOnClickRedeem = () => {
    this.setState({ showConfirmRedeemModal: true });
  }

  handleOnCloseRedeemModal = () => {
    this.setState({ showConfirmRedeemModal: false, redeeming: false });
  }

  handleRedeem = () => {
    this.setState({
      redeeming: true
    });

    const { match } = this.props;

    return api(`/giftcard/${match.params.shortcode}/redeem`, {
      method: 'GET'
    }).then((res) => {

      this.setState({
        redeeming: false
      });

      if (res.success) {
        //sleep for 3 seconds
        setTimeout(() => {
          this.loadGiftcard(match.params.shortcode)
        }, 3000);
        this.setState({ showConfirmRedeemModal: false });
      }
    })
  }

  renderImage = (imageSrc, name) => {

    // let imageSrc = ''

    // if(theme === "Happy Birthday"){
    //   imageSrc = "https://cdn.shopify.com/s/files/1/1428/2106/files/Happy_Birthday.gif?v=1589021305"
    // } else if (theme === "With Love"){
    //   imageSrc = "https://cdn.shopify.com/s/files/1/1428/2106/files/With_Love.gif?v=1589020820"
    // } else if (theme === "Best Wishes"){
    //   imageSrc = "https://cdn.shopify.com/s/files/1/1428/2106/files/Best_Wishes.gif?v=1589020820"
    // } else if (theme === "Congrats"){
    //   imageSrc = "https://cdn.shopify.com/s/files/1/1428/2106/files/Congrats.gif?v=1589020820"
    // } else if (theme === "Thank You"){
    //   imageSrc = "https://cdn.shopify.com/s/files/1/1428/2106/files/Thank_You.gif?v=1589020820"
    // } else if (theme === "Best of Luck"){
    //   imageSrc = "https://cdn.shopify.com/s/files/1/1428/2106/files/Best_of_Luck.gif?v=1589020820"
    // } else if (theme === "Hello"){
    //   imageSrc = "https://cdn.shopify.com/s/files/1/1428/2106/files/Hello.gif?v=1589020819"
    // }

    // return (<img className="img-reponsive image-preview" alt={theme} src={imageSrc}/>)

    return (<img className="img-reponsive image-preview" alt={name} src={imageSrc}/>)
  }

  renderValue = () => {
    const { item } = this.state;

    if (item.new_value_format) {
      return <span id="preview-variant-title">{item.new_value}</span>
    } else if (item.value) {
      return <span id="preview-variant-title">RM{typeof item.value === 'number' ? item.value.toFixed() : item.value}</span>
    }
  }

  render(){

    const { loading, item, youtube, showConfirmRedeemModal, redeeming, redeemError } = this.state;

    return (
      <section id="giftcard">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8 col-sm-12">
              <div className="giftcard-preview-contianer mt-3">
                {
                  loading && <Loader/>
                }
                {
                  item && !loading &&
                    <div>
                      <h1 className="mb-3 mt-1">JUST FOR YOU</h1>
                      {this.renderImage(item.image_url, item.name)}
                      <div className="preview-content-contianer">
                        <div id="preview-receipient" className="preview-receipeint">Hi <span id="preview-receiver">{item.receiver_name}</span>,</div>
                        <div className="preview-giftcard-message">
                          <p>You've received {item.name} worth {this.renderValue()} from <span id="preview-sender">{item.senders_name}</span>.
                          </p>
                        </div>
                        <div id="preview-code-expiry">
                          <p>Your unique code:</p>
                          <p id="preview-code">{item.giftcode_code}</p>
                          { item.redeemed &&
                          <p className="preview-redeemed">Gift Card redeemed on {moment(item.redeemed_on).format('Do MMMM YYYY')}</p>
                          }
                          <p>Expiry date:</p>
                          <p id="preview-expiry">{moment(item.expiry_date).format('Do MMMM YYYY')}</p>

                          {
                            item.redemption_type === 'pre' && !item.redeemed &&
                            <Button
                              variant="primary"
                              className="w-100 btn-br-6"
                              onClick={this.handleOnClickRedeem}
                            >Redeem</Button>
                          }
                        </div>
                        {
                          item.exclude_message !== 'yes' &&
                            <div id="preview-message" className="preview-message">
                              <p>Here's a special message for you:</p>
                              <div id="preview-message-content">
                                {item.message}
                              </div>
                              {
                                item.virtual_message &&
                                  <div className="row">
                                    <div id="col-main" className="col-sm-12">

                                      <div className="vm-container">

                                        <div className="c-audio hide">
                                          <div id="waveform"></div>
                                          <div className="a-controls">
                                            <div className="time">
                                              <span id="a-current">0:00</span> / <span id="a-duration">0:00</span>
                                            </div>
                                            <div className="play-pause">
                                              <button id="playPauseButton"></button>
                                            </div>
                                            <div className="volume">
                                              <div className="a-vol" style={{top: '75%'}}>
                                                <button onClick={this.audioButton}></button>
                                                <div id="a-slider"></div>
                                              </div>
                                            </div>
                                            <div className="a-download">
                                              <a id="a-dl-link" download="virtualmessage-audio.mp3"><img src="https://cdn.shopify.com/s/files/1/1428/2106/t/5/assets/download-button.svg?v=35773428140899117901642061031"/></a>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="c-video hide" id="vm-video">
                                          <video id="video" preload="metadata" playsInline>
                                          </video>
                                          <div className="v-controls">
                                            <div className="teal-bar">
                                              <div id="seek-slider"></div>
                                            </div>
                                            <div className="time">
                                              <span id="v-current">0:00</span> / <span id="v-duration">0:00</span>
                                            </div>
                                            <div className="play-pause">
                                              <button id="play-pause" onClick={this.playPause}></button>
                                            </div>
                                            <div className="volume">
                                              <div className="download">
                                                <a id="dl-link" download="virtualmessage-video.mp4"><img src="https://cdn.shopify.com/s/files/1/1428/2106/t/5/assets/download-button.svg?v=35773428140899117901642061031"/></a>
                                              </div>
                                              <div className="vol-button">
                                                <button onClick={this.volumeButton}></button>
                                                <div id="vol-slider"></div>
                                              </div>
                                              <div className="fullscreen">
                                                <button id="fullscreen" onClick={this.fullscreen}></button>
                                              </div>
                                            </div>

                                          </div>
                                        </div>

                                        <div class="hide c-video" id="yt-embed">
                                        </div>

                                        {
                                          !youtube &&
                                            <div>
                                              <p style={{fontSize: '0.9rem'}}><i style={{color: '#909090'}}>Uploaded media is available for 3 months only. You may download a copy for your own reference </i>&#x1F642;</p>
                                            </div>
                                        }
                                      </div>


                                    </div>
                                  </div>
                              }
                            </div>
                        }
                        <p>Enjoy this special gift!</p>
                        <hr />
                        {
                          item.terms &&
                            <div className="preview-validity">Terms and Conditions: {parse(item.terms)}</div>
                        }
                        <h5 id="gifting-made-easy" >- Gifting Made Easy -</h5>
                      </div>
                    </div>
                }
              </div>
            </div>
          </div>
        </div>
        {
          showConfirmRedeemModal &&
          <RedeemGiftcardModal redeeming={redeeming} redeemError={redeemError} onClickSave={this.handleRedeem} onClose={this.handleOnCloseRedeemModal} title="Redeem"/>
        }
      </section>
    )
  }
}

export default Giftcard;
