import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (<div></div>)
  // return (
  //   <section id="giftcard">
  //     <div className="container">
  //       <div className="row justify-content-center">
  //         <div className="col-lg-6 col-md-8 col-sm-12">
  //           <div className="giftcard-preview-contianer mt-5">
  //             <img id="image-preview" className="img-reponsive image-preview" src="https://d1o7uku192uawx.cloudfront.net/mobile/media/catalog/customoptions/Vgifts-EDMBANNER-Happy_BirthdayHB_600x250.jpg"/>
  //             <div className="preview-content-contianer">
  //               <div id="preview-receipient" className="preview-receipeint">Hi Receiver,</div>
  //               <div className="preview-giftcard-message">You've got a xyz E-Gift Card</div>
  //               <div id="preview-message" className="preview-message">Your message will appear here</div>

  //               <div className="preview-price">RM30.00</div>

  //               <div className="preview-label">Card Number</div>
  //               <div className="preview-label-text">xxxxxxx</div>

  //               <div className="preview-label">Pin</div>
  //               <div className="preview-label-text">xxxxxxx</div>

  //               <div className="preview-validity">*Validity: xx xx xxxx</div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </section>
  // );
}

export default App;
